import React from 'react';

export const Video = ({ videoInfo }) => {
  return (
    <div className="w-full px-6 lg:px-36 py-16 flex flex-col flex-wrap bg-s4tk-orange justify-around items-center">
      <div className="relative w-full md:w-3/4 h-0 pb-16/9 md:pb-16/more">
        <div className="absolute w-full h-full z-0 bg-black opacity-25 top-2 left-2 lg:top-8 lg:left-8"></div>
        <iframe
          src={videoInfo.url}
          title="video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          className="w-full h-full absolute top-0 left-0 z-10"
        ></iframe>
      </div>
    </div>
  );
};

export const VideoBlank = ({ videoInfo }) => {
  return (
    <div className="w-full px-6 lg:px-36 py-16 flex flex-col flex-wrap justify-around items-center">
      <div className="relative w-full md:w-3/4 h-0 pb-16/9 md:pb-16/more">
        <div className="absolute w-full h-full z-0 bg-black opacity-25 top-2 left-2 lg:top-8 lg:left-8"></div>
        <iframe
          src={videoInfo.url}
          title="video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          className="w-full h-full absolute top-0 left-0 z-10"
        ></iframe>
      </div>
    </div>
  );
};

export default Video;
