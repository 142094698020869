import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import TestimonialsContent from '../components/About/TestimonialsContent';

export const TestimonialsPageTemplate = ({
  athletes,
  parents,
  extra,
  videos
}) => {
  return (
    <div className="w-full flex flex-col bg-s4tk-white">
      <TestimonialsContent
        athletes={athletes}
        parents={parents}
        extra={extra}
        videos={videos}
      />
    </div>
  );
};

const TestimonialsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <TestimonialsPageTemplate
        athletes={frontmatter.athletes}
        parents={frontmatter.parents}
        extra={frontmatter.extra}
        videos={frontmatter.videos}
      />
    </Layout>
  );
};

export default TestimonialsPage;

export const testimonialsPageQuery = graphql`
  query TestimonialsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "testimonials-page" } }) {
      frontmatter {
        athletes {
          text
          author
        }
        parents {
          text
          author
        }
        extra {
          text
          author
        }
        videos {
          url
        }
      }
    }
  }
`;
