import React from 'react';
import { motion } from 'framer-motion';
import Video from '../Home/Video';

import grass from '../../img/grass.svg';
import cloud from '../../img/cloud3.svg';

// style={{ clipPath: 'polygon(0 0, 0 66%, 100% 100%, 100% 0)' }}

export const TestimonialsContent = ({ athletes, parents, extra, videos }) => {
  return (
    <div className="w-full min-h-screen bg-blue-300 relative flex flex-col items-center justify-between">
      <div
        style={{
          borderImage: `url(${cloud}) 20`,
          borderBottomWidth: '200px',
          borderImageWidth: '1'
        }}
        className="flex bg-blue-300 flex-col items-center w-full z-0"
      >
        <div className="w-full bg-gradient-to-b from-white to-gray-200 flex flex-col items-center space-y-12 py-16 px-4 md:px-24 lg:px-48 xl:px-56 2xl:px-72">
          {athletes.map((item, index) => (
            <motion.div
              className={`w-full sm:w-96 2xl:w-2/5 px-4 flex flex-col ${
                index % 2 === 0 ? 'self-start' : 'self-end'
              }`}
              animate={{ opacity: [0, 1] }}
              transition={{ duration: 1.5 }}
            >
              <p
                animate={{ opacity: [0, 1] }}
                transition={{ duration: 1.5 }}
                className="text-2xl 2xl:text-4xl font-baloo text-black text-left sm:text-justify font-bold self-start pr-4"
              >
                {`"${item.text}"`}
              </p>
              <p className="self-end text-xl 2xl:text-3xl font-baloo text-black">
                {item.author}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
      <div className="w-full relative bg-green-500 flex flex-col items-center justify-between">
        <div className="w-full h-1 bg-blue-300 absolute top-0 left-0" />
        <div className="flex bg-blue-300 flex-col items-center w-full py-16">
          <div className="w-full flex flex-col items-center space-y-12 px-4 md:px-24 lg:px-48 xl:px-56 2xl:px-72">
            {parents.map((item, index) => (
              <motion.div
                className={`w-full sm:w-96 2xl:w-2/5 px-4 flex flex-col ${
                  index % 2 === 0 ? 'self-start' : 'self-end'
                }`}
                animate={{ opacity: [0, 1] }}
                transition={{ duration: 1.5 }}
              >
                <p
                  animate={{ opacity: [0, 1] }}
                  transition={{ duration: 1.5 }}
                  className="text-2xl 2xl:text-4xl font-baloo text-black font-bold self-start pr-4"
                >
                  {`"${item.text}"`}
                </p>
                <p className="self-end text-xl 2xl:text-3xl font-baloo text-black">
                  {item.author}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
        <div className="relative w-full">
          <div className="bg-green-500 w-full h-full absolute bottom-0" />
          <div
            style={{
              backgroundImage: `url(${grass})`
            }}
            className="w-full absolute bottom-0 h-full bg-repeat"
          />
          <div className="w-full flex flex-col items-center space-y-12 px-4 md:px-24 lg:px-48 xl:px-56 2xl:px-72 py-16">
            {extra.map((item, index) => (
              <motion.div
                className={`w-full sm:w-96 2xl:w-2/5 px-4 flex flex-col z-10 ${
                  index % 2 === 0 ? 'self-start' : 'self-end'
                }`}
                animate={{ opacity: [0, 1] }}
                transition={{ duration: 1.5 }}
              >
                <p
                  animate={{ opacity: [0, 1] }}
                  transition={{ duration: 1.5 }}
                  className="text-2xl 2xl:text-4xl font-baloo text-black font-bold self-start pr-4"
                >
                  {`"${item.text}"`}
                </p>
                <p className="self-end text-xl 2xl:text-3xl font-baloo text-black">
                  {item.author}
                </p>
              </motion.div>
            ))}
            {videos.map((item, index) => (
              <Video key={index} videoInfo={item} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsContent;
